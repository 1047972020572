import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import SubHeader from 'components/PageHeader'
import Slider from 'react-slick'
import {ensembleColor} from 'styles/colors'
import styled from 'styled-components'

const Page = styled.div`
  @media (min-width: 769px) {
    height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
  }  
`

const Col = styled.div`
  flex: 1;
  text-align: justify;
  @media (min-width: 769px) {
    padding: 0 2rem;
  }
  font-size: 0.85rem;
  line-height: 1.2rem;
  > p {
    margin-bottom: 0.5rem;
  }
`

const CenterCol = styled.div`
  text-align: center;
`

const UpperArea = styled.div`
  @media (min-width: 769px) {
    flex-direction: row;
    display: flex;
  }
`

const PictureRow = styled.div`
  @media (min-width: 769px) {
    flex: 1;
    display: flex;
    width: 100%;
    align-items: center;
    > div {
      min-width: 12rem;
      max-height: 10rem;
      flex: 1;
    }
  }
`

const LowerArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const SliderWrap = styled.div`
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

const LeftButton = styled.div`
  @media (max-width: 768px) {
    pointer-events: none;
  }
  position: absolute;
  height: 100px;
  width: 100px;
  background: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 100;
`

const RightButton = styled.div`
  @media (max-width: 768px) {
    pointer-events: none;
  }
  position: absolute;
  right: 0;
  height: 100px;
  width: 110px;
  background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1));
  z-index: 100;
`

const SliderLabel = styled.div`
  text-align: center;
  color: ${ensembleColor};
  font-size: 1.2rem;
  font-variant: small-caps;
`

const Picture = styled.div`
  > img {
    margin: auto;
  }
`

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  centerPadding: 0,
  focusOnSelect: true
}

class Singers extends Component {
  componentDidMount () {
    if (!this.props.groupedUserImages) {
      this.props.loadUserImages()
    }
  }

  render () {
    return (
      <Page>
        <UpperArea>
          <Col>
            <p>
              Die "Moving Colours" bringen seit ihrer Gründung durch Karin Mueller in 2007 frischen Wind in die
              Berliner Musikszene. Mit einem vielseitigen Mix aus Jazz, Soul, und Pop sorgt der Chor auf
              unterschiedlichsten Bühnen – von kleinen Sälen über Kirchen bis hin zur Philharmonie – für ordentlich Stimmung.
            </p>
            <p>
              Unter der Leitung von Sven Ratzel interpretiert der Chor stimmungsvolle Songs, die das
              Publikum mitreißen. Die rund 25 Sängerinnen und Sänger lassen in bis zu acht Stimmlagen
              einen satten, vollen Sound entstehen. Die "Moving Colours" lieben es, neue musikalische
              Wege zu erkunden, und überraschen mit groovigen und unkonventionellen Stücken aus
              verschiedenen Jahrzehnten.
            </p>
          </Col>
          <CenterCol>
            <SubHeader color={ensembleColor}>Ein Ensemble</SubHeader>
            <SubHeader color={ensembleColor}>Alle Tonlagen</SubHeader>
          </CenterCol>
          <Col>
            <p>
              Die "Moving Colours" widmen sich vor allem modernen Stücken, die durch rhythmische und
              harmonische Raffinesse herausfordern. Ihr Repertoire umfasst mehrere Jahrzehnte und legt
              einen besonderen Fokus auf aktuelle Musik, wobei auch Einflüsse von etablierten
              Ensembles aus Deutschland und Skandinavien eine Rolle spielen. Zusätzlich greifen sie auf
              speziell für sie angefertigte Arrangements zurück.
            </p>
            <p>
              Gemeinsam arbeiten die Chormitglieder daran, ihre gesanglichen Fähigkeiten mit jedem
              Stück und jedem Konzert weiterzuentwickeln, den Horizont des Ensembles zu erweitern und
              das Publikum mit auf diese Reise zu nehmen – und hoffentlich zu begeistern. Dabei sind die
              wichtigsten Säulen des Chores die Liebe zur Musik, der Spaß am gemeinsamen Musizieren
              und ein starkes Miteinander, das weit über die Proben hinausgeht.
            </p>
            <p>
              Der Chor wird eigenständig von allen Mitgliedern organisiert, wobei jede*r einzelne aktiv
              eingebunden ist. Chorleiter Sven Ratzel steht ihnen dabei als inspirierende und
              unterstützende Kraft zur Seite.
            </p>
          </Col>
        </UpperArea>
        {this.props.groupedUserImages &&
          <LowerArea>
            <PictureRow>
              {this.props.groupedUserImages.sopran &&
                <SliderWrap>
                  <LeftButton onClick={() => this.sopranSlider.slickPrev()} />
                  <RightButton onClick={() => this.sopranSlider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.sopranSlider = c }}>
                    {this.props.groupedUserImages.sopran.map((entry, index) =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Sopran</SliderLabel>
                </SliderWrap>
              }
              {this.props.groupedUserImages.mezzo &&
                <SliderWrap>
                  <LeftButton onClick={() => this.mezzoSlider.slickPrev()} />
                  <RightButton onClick={() => this.mezzoSlider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.mezzoSlider = c }}>
                    {this.props.groupedUserImages.mezzo.map(entry =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Mezzo</SliderLabel>
                </SliderWrap>
              }
              {this.props.groupedUserImages.alt1 &&
                <SliderWrap>
                  <LeftButton onClick={() => this.alt1Slider.slickPrev()} />
                  <RightButton onClick={() => this.alt1Slider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.alt1Slider = c }}>
                    {this.props.groupedUserImages.alt1.map(entry =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Alt I</SliderLabel>
                </SliderWrap>
              }
              {this.props.groupedUserImages.alt2 &&
                <SliderWrap>
                  <LeftButton onClick={() => this.alt2Slider.slickPrev()} />
                  <RightButton onClick={() => this.alt2Slider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.alt2Slider = c }}>
                    {this.props.groupedUserImages.alt2.map(entry =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Alt II</SliderLabel>
                </SliderWrap>
              }
            </PictureRow>
            <PictureRow>
              {this.props.groupedUserImages.tenor1 &&
                <SliderWrap>
                  <LeftButton onClick={() => this.tenor1Slider.slickPrev()} />
                  <RightButton onClick={() => this.tenor1Slider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.tenor1Slider = c }}>
                    {this.props.groupedUserImages.tenor1.map((entry, index) =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Tenor I</SliderLabel>
                </SliderWrap>
              }
              {this.props.groupedUserImages.tenor2 &&
                <SliderWrap>
                  <LeftButton onClick={() => this.tenor2Slider.slickPrev()} />
                  <RightButton onClick={() => this.tenor2Slider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.tenor2Slider = c }}>
                    {this.props.groupedUserImages.tenor2.map(entry =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Tenor II</SliderLabel>
                </SliderWrap>
              }
              {this.props.groupedUserImages.bass1 &&
                <SliderWrap>
                  <LeftButton onClick={() => this.bass1Slider.slickPrev()} />
                  <RightButton onClick={() => this.bass1Slider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.bass1Slider = c }}>
                    {this.props.groupedUserImages.bass1.map(entry =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Bass I</SliderLabel>
                </SliderWrap>
              }
              {this.props.groupedUserImages.bass2 &&
                <SliderWrap>
                  <LeftButton onClick={() => this.bass2Slider.slickPrev()} />
                  <RightButton onClick={() => this.bass2Slider.slickNext()} />
                  <Slider {...sliderSettings} ref={c => { this.bass2Slider = c }}>
                    {this.props.groupedUserImages.bass2.map(entry =>
                      <Picture key={entry._id}><img alt='' src={entry.image} height='100' /></Picture>
                    )}
                  </Slider>
                  <SliderLabel>Bass II</SliderLabel>
                </SliderWrap>
              }
            </PictureRow>
          </LowerArea>
        }
      </Page>
    )
  }
}

export default connect(
  state => ({
    groupedUserImages: state.common.groupedUserImages
  }),
  dispatch => ({
    loadUserImages: () => dispatch(common.actions.loadUserImages())
  })
)(Singers)
